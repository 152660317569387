import React from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
// import Footer from "./Footer";
// import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "#fff",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    // minHeight: "100vh",
  },
  MainLayout: {
    // width: "100%",
    // // maxWidth: "1200px",
    // padding: "0 20px",
    // boxSizing: "border-box",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  console.log(history.location);
  return (
    <div className={classes.root}>
      {/* <TopBar /> */}
      <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>

      <div className={classes.MainLayout}>{children}</div>
      {/* {window.location.pathname ==="/" && ( */}
      {/* <Footer /> */}

      {/* )} */}
    </div>
  );
};

export default MainLayout;
