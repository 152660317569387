import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
const useStyles = makeStyles((theme) => ({
  home:{
color:"#263238",
fontFamily: "Nunito Sans",
fontWeight: "400",
fontSize: "16px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 80px",
    "@media (max-width: 1100px)": {
      margin: "0 20px",
    },
    "@media (max-width: 400px)": {
      margin: "0px -10px",
    },
    // Align items to the right
  },
  appBar: {
    // position: "sticky",
    backgroundColor: "white",
    boxShadow: "none",
    // width:"100%"
  },

  logo: {
    border: "none",
    alignItems: "center",
  },
  smallDevice: {
    margin: "0 1%",
  },
  ul: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    listStyleType: "none",
  },
  link: {
    marginLeft: "20px",
    // color: activeTab === "link" ? "#F7931E" : "#263238", // Orange if active, else default color
    fontFamily: "Nunito Sans",
    fontWeight: "600px",
    fontSize: "16px",
    "@media (max-width: 800px)": {
      fontSize: "10px",
    },
  },

  button: {
    // textDecoration: "none",
    color: "#263238",
    fontFamily: "Nunito Sans",
    fontWeight: "600px",
    fontSize: "16px",
    textTransform: "none",
    "@media (max-width: 800px)": {
      fontSize: "10px",
    },
    "&:hover": {
      backgroundColor: "white !important",
      color: "#F7931E",
    },
    "&:active": {
      backgroundColor: "white !important",
      color: "#F7931E",
    },
  },
  divider: {
    width: "1px",
    height: "30px",
    color: "#E7E7E7",
    backgroundColor: "#E7E7E7",
    orientation: "vertical",
    marginLeft: "20px",
  },
  cart: {
    marginLeft: "20px",
    cursor: "pointer",
  },
  loginButton: {
    marginLeft: "20px",

    backgroundColor: "#F7931E",
    // padding:"10px",
    height: "40px",
    width: "120px",
    borderRadius: "30px",
    textTransform: "none",
    color: "white",
    fontWeight: "600px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#F7931E !important",
    },
    "&:active": {
      backgroundColor: "#F7931E !important",
    },
    "@media (max-width: 900px)": {
      height: "30px",
      marginLeft: "10px",
      width: "90px",
    },
    "@media (max-width: 400px)": {
      height: "25px",
      marginLeft: "5px",
      width: "60px",
      fontSize: "12px",
    },
  },
  profileBox:{
    height:"40px",
    width:"40px",
    borderRadius:"50px"
  },
  shopingCartBox: {
    margin: "5%",
    display: "flex",
    justifyContent: "space-between",
  },
  shopingCartBox2: {
    margin: "5%",
    display: "flex",
    justifyContent: "center",
  },
  yourCart: {
    color: "#2D2C2C",
    fontSize: "20px",
    fontWeight: "700",
    fontFamily: "Playfair Display",
  },
  emptyCart: {
    color: "rgba(38, 38, 40, 0.6)",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Nunito Sans",
  },
  divider2: {
    height: "1px",
width:"100%",
    color: "#E7E7E7",
    backgroundColor: "#E7E7E7",
  },
  cancel: {
    cursor: "pointer",
    height: "27px",
    width: "27px",
    backgroundColor: "#F7931E",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5%",
  },
  cartLogin: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5%",
  },
  moreAboutButton: {
    marginTop: "20px",
    backgroundColor: "#F7931E",
    padding: "10px 20px",
    height: "50px",
    width: "256px",
    borderRadius: "30px",
    textTransform: "none",
    color: "white",
    fontWeight: "600px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#F7931E !important",
    },
    "&:active": {
      backgroundColor: "#F7931E !important",
    },
  },
  divider3: {
    height: "1px",
    margin: "20px 0px",
    color: "#E7E7E7",
    backgroundColor: "#E7E7E7",
  },
  viewCart: {
    marginTop: "20px",
    backgroundColor: "#fff",
    padding: "10px 20px",
    height: "50px",
    width: "256px",
    border: "1px solid #F7931E",
    borderRadius: "30px",
    textTransform: "none",
    color: "#262626",
    fontWeight: "600px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#fff !important",
    },
    "&:active": {
      backgroundColor: "#fff !important",
    },
  },
  afterLoginCart: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5%",
  },
  drawer: {
    width: "370px",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "250px",
    },
  }
}));

function Header() {
  const theme = useTheme();
  const history = useHistory();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  // const [activeTab, setActiveTab] = useState("");
  const [cartValue, setCartValue] = useState(1);
  const [price, setprice] = useState(360);
  const [activeButton, setActiveButton] = useState("");
  const classes = useStyles();
  // Update handleTabClick to set the active button
  const handleTabClick = (tabName) => {
    setActiveButton(tabName);
  };
 
  const toggleDrawer2 = () => {
    setDrawerOpen(!drawerOpen);
    setButtonClicked(!buttonClicked);
  };

  const handleButtonClick = () => {
    setButtonClicked(!buttonClicked);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //----------Shoping Drawer
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (
   
    <Box >
    <Box className={classes.shopingCartBox}>
        <Typography className={classes.yourCart}>Your Cart</Typography>
        <Box className={classes.cancel} onClick={toggleDrawer("right", false)}>
          <CloseIcon style={{ color: "#fff" }} />
        </Box>
      
        </Box>
      <Divider className={classes.divider2} />

      <Box className={classes.cart}>
        <img
          src="Image/cartimage.png"
          alt="shopping"
          height={100}
          width={100}
        />
      </Box>

      <Box className={classes.shopingCartBox2}>
        <Typography className={classes.emptyCart}>Your cart is currently empty.</Typography>
       
      </Box>

      <Box className={classes.cartLogin}>
        <Button className={classes.moreAboutButton} onClick={()=>{history.push("/login")}} > Login & Continue Shopping</Button>
      </Box>
    </Box>
   
  );

  const list2 = (
    <Box  >
    <Box className={classes.shopingCartBox}>
        <Typography className={classes.yourCart}>Your Cart</Typography>
        <Box className={classes.cancel} onClick={toggleDrawer("right", false)}>
          <CloseIcon style={{ color: "#fff" }} />
        </Box>
      
        </Box>
      <Divider className={classes.divider2} />

      <Grid container>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Box style={{ padding: "10%" }}>
            <img
              src="Image/blackTea.png"
              alt="Img"
              style={{ height: "100%", width: "100%" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Box style={{ padding: "0 25% 0 10%" }}>
            <Typography
              style={{
                fontFamily: "Playfair Display",
                fontWeight: "700",
                fontSize: "18px",
              }}
            >
              Classic Black Tea
            </Typography>
            <Typography
              style={{
                fontFamily: "Nunito Sans",
                fontWeight: "400",
                fontSize: "16px",
                marginTop: "10px",
                color: "rgba(38, 38, 40, 0.6)",
              }}
            >
              250 gm / Peppermint / Light green
            </Typography>
            <Typography
              style={{
                fontFamily: "Nunito Sans",
                fontWeight: "700",
                fontSize: "18px",
                marginTop: "10px",
                color: "rgba(77, 140, 64, 1)",
              }}
            >
              Rs. {price}.00
            </Typography>

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "30px",
                  width: "30px",
                  border: "1px solid #E9E9E9",
                  borderRadius: "10px 0px 0px 10px",
                  cursor: "pointer",
                }}
              >
                <RemoveIcon
                  onClick={() => cartValue !== 0 && setCartValue(cartValue - 1)}
                  style={{ color: "#F7931E" }}
                />
              </Box>

              <Box
                style={{
                  height: "30px",
                  width: "30px",
                  border: "1px solid #E9E9E9",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Playfair Display",
                    fontSize: "18px",
                    fontWeight: "400",
                  }}
                >
                  {cartValue}
                </Typography>
              </Box>

              <Box
                style={{
                  height: "30px",
                  width: "30px",
                  border: "1px solid #E9E9E9",
                  borderRadius: "0px 10px 10px 0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <AddIcon
                  onClick={() =>
                    cartValue !== 50 && setCartValue(cartValue + 1)
                  }
                  style={{ color: "#F7931E" }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider className={classes.divider3} />

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "0  25px",
        }}
      >
        <Typography
          style={{
            fontFamily: "Nunito Sans",
            fontSize: "18px",
            fontWeight: "700",
          }}
        >
          Total{" "}
        </Typography>
        <Typography
          style={{
            fontFamily: "Nunito Sans",
            fontSize: "18px",
            fontWeight: "700",
          }}
        >
          Rs. {cartValue * price}.00
        </Typography>
      </Box>
      <Divider className={classes.divider3} />

      <Typography
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "rgba(38, 38, 40, 0.6)",
          fontFamily: "Nunito Sans",
          fontSize: "16px",
          fontWeight: "400",
          padding: "0 25px",
        }}
      >
        Shipping, taxes, and discount will be calculated at checkout
      </Typography>

      <Box className={classes.afterLoginCart}>
        <Button className={classes.moreAboutButton}> Proceed to Checkout</Button>
        <Button className={classes.viewCart}> View Cart</Button>
      </Box>
    </Box>
    
  );
  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Box className={classes.logoImage} onClick={scrollToTop}>
            <img
              src="/Image/logo1.png"
              height="69px"
              width="83px"
              alt="Logo"
              className={classes.logo}
            />
          </Box>
          {isSmallScreen ? (
            <Box className={classes.smallDevice}>
              <IconButton onClick={toggleDrawer("right", true)}>
                {" "}
                <ShoppingCartIcon />{" "}
              </IconButton>
              <IconButton>
                {" "}
                <SearchIcon />{" "}
              </IconButton>
              <Button className={classes.loginButton}> Login</Button>
              <IconButton onClick={toggleDrawer2}>
                <MenuIcon />
              </IconButton>
            </Box>
          ) : (
            <Box className={classes.ul}>
              <Button
                component={Link}
                to="/login-sucess"
                
                onClick={() => handleTabClick("home")}
                className={classes.home}
              >
                Home
              </Button>
              <Button onClick={() => history.push("/aftercollection")} className={classes.home}>
                Collections
              </Button>
              {/* <Button
                component={Link}
                to="/buy-product"
                
                onClick={() => handleTabClick("product")}
                className={classes.home}
              >
                Buy Products
              </Button> */}
              <Button
                component={Link}
                to="/about-us"
                
                onClick={() => handleTabClick("aboutus")}
                className={classes.home}
              >
                About Us
              </Button>

              <Button
                component={Link}
                to="/contact-us"
               
                onClick={() => handleTabClick("contact")}
                className={classes.home}
              >
                Contact Us
              </Button>

              <Divider className={classes.divider} />
              <IconButton>
              <FavoriteBorderIcon/>
              </IconButton>
             
              <IconButton onClick={toggleDrawer("right", true)}>
                <ShoppingCartIcon style={{color:"#F7931E"}}/>
              </IconButton>
              <IconButton>
                <SearchIcon />
              </IconButton>
              <Box className={classes.profileBox} onClick={()=> {history.push()}}>
                <img src="Image/profileLogo.png" alt="profileLogo " height="100%" width="100%" />
              </Box>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer2}>
        <List>
          <ListItem
            button
            onClick={() => {
              toggleDrawer2();
              handleButtonClick();
            }}
          >
            <ListItemText>
              <Link to="/login-sucess" className={classes.link}>
                Home
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              toggleDrawer2();
              handleButtonClick();
            }}
          >
            <ListItemText>
              <Link to="/aftercollection" className={classes.link}>
                Collections
              </Link>
            </ListItemText>
          </ListItem>
          {/* <ListItem
            button
            onClick={() => {
              toggleDrawer2();
              handleButtonClick();
            }}
          >
            <ListItemText>
              <Link to="/buy-product" className={classes.link}>
                Buy Products
              </Link>
            </ListItemText>
          </ListItem> */}
          <ListItem
            button
            onClick={() => {
              toggleDrawer2();
              handleButtonClick();
            }}
          >
            <ListItemText>
              <Link to="/about-us" className={classes.link}>
                About Us
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              toggleDrawer2();
              handleButtonClick();
            }}
          >
            <ListItemText>
              <Link to="/contact-us" className={classes.link}>
                Contact Us
              </Link>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>

      <Drawer
  anchor="right"
  open={state["right"]}
  onClose={toggleDrawer("right", false)}
  classes={{ paper: classes.drawer }}
>
  {list2}
</Drawer>

    </>
  );
          }

export default Header;