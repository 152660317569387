// export const NetworkContextName = "NETWORK";
// export const ACTIVE_NETWORK = 42;
// export const tokenContract = "0xE4861c8C1A80250e1e280F7F7bbf84736146b867";
// export const MarketplaceContract = "0xf5DE7F4Ee0C4063a1e6f139fEa2eB92c2D153653";
// export const NftTokenAddress = "0x4846666e4013A48647be98AF3FDE33251e679bd2";

export const NetworkContextName = "NETWORK";
export const Network = "kovan";
export const ACTIVE_NETWORK = 42;
export const tokenContract = "0xE4861c8C1A80250e1e280F7F7bbf84736146b867";
export const MarketplaceContract = "0xf5DE7F4Ee0C4063a1e6f139fEa2eB92c2D153653";
export const NftTokenAddress = "0x4846666e4013A48647be98AF3FDE33251e679bd2";



export const ErcTokenAddress = "0x224d9b2FeFe54A83FD9e098Cfe79c6c675edd44f";
export const BUSDAddress = "0x4fabb145d64652a948d72533023f6e7a623c7c53"; //mainNet address
// export const NftTokenAddress = "0xD5CFCEEB62366913480a41D9D980d4332F41DB36";
export const MarketPlaceAddress = "0x6Eae4b6F60067AC67AE6EA417803891091153e9C";
export const nativeTokenCheck = "50";

export const CategoryButtons = [
    {
      name: "Hot & Top Sellers",
    },
    {
      name: "Gaming Items",
    },
    {
      name: "Metaverse",
    },
    {
      name: "Art",
    },
    {
      name: "Videos",
    },
    {
      name: "Letter of wishes",
    },
    {
      name: "Web 3.0",
    },
    
  ];
  export const RankingButtons = [
    {
      name: "Last 10 Days",
    },
    {
      name: "Last 30 Days",
    },
    {
      name: "Last 10 Days",
    },
    {
      name: "Last 20 Days",
    },
    {
      name: "Last 7 Days",
    },
    {
      name: "Last 15 Days",
    },
    {
      name: "Last 30 Days",
    },
    
  ];


  export const collectionData = [
    {
        image: "images/Collections/Collection1.png",
        img: "images/Sellers/Sellers1.png",
        name: "Sophia Valentine",
        price: "0.99  ETH"
    },
    {
        image: "images/Collections/Collection2.png",
        img: "images/Sellers/Sellers2.png",
        name: "The Metakey",
        price: "0.43  ETH"
    },
    {
        image: "images/Collections/Collection3.png",
        img: "images/Sellers/Sellers3.png",
        name: "Diamond HODLR",
        price: "4.73  ETH"
    },
    {
        image: "images/Collections/Collection4.png",
        img: "images/Sellers/Sellers4.png",
        name: "Sophia Valentine",
        price: "5.22  ETH"
    },
    {
        image: "images/Collections/Collection5.png",
        img: "images/Sellers/Sellers1.png",
        name: "Sophia Valentine",
        price: "0.99  ETH"
    },
    {
        image: "images/Collections/Collection1.png",
        img: "images/Sellers/Sellers2.png",
        name: "The Metakey",
        price: "0.43  ETH"
    },
    {
        image: "images/Collections/Collection2.png",
        img: "images/Sellers/Sellers3.png",
        name: "Diamond HODLR",
        price: "4.73  ETH"
    },
    {
        image: "images/Collections/Collection3.png",
        img: "images/Sellers/Sellers4.png",
        name: "Sophia Valentine",
        price: "5.22  ETH"
    },
];

export const exploreData = [
  {
      user: "@Alex",
      likes: "152",
      time: "8h : 15m : 25s left",
      stock: "4 in stock",
      text3: "From 1.35 ETH 11/Bid 1.1 w",
      image: "images/Explore/Explore1.png",
      name: "Skyblue Creator",
      price: "0.004 ETH"
  },
  {
      user: "@Alex",
      likes: "152",
      time: "8h : 15m : 25s left",
      stock: "4 in stock",
      text3: "From 1.35 ETH 11/Bid 1.1 w",
      image: "images/Explore/Explore2.png",
      name: "Skyblue Creator",
      price: "0.004 ETH"
  },
  {
      user: "@Alex",
      likes: "152",
      time: "8h : 15m : 25s left",
      stock: "4 in stock",
      text3: "From 1.35 ETH 11/Bid 1.1 w",
      image: "images/Explore/Explore3.png",
      name: "Skyblue Creator",
      price: "0.004 ETH"
  },
  {
      user: "@Alex",
      likes: "152",
      time: "8h : 15m : 25s left",
      stock: "4 in stock",
      text3: "From 1.35 ETH 11/Bid 1.1 w",
      image: "images/Explore/Explore4.png",
      name: "Skyblue Creator",
      price: "0.004 ETH"
  },
  {
      user: "@Alex",
      likes: "152",
      time: "8h : 15m : 25s left",
      stock: "4 in stock",
      text3: "From 1.35 ETH 11/Bid 1.1 w",
      image: "images/Explore/Explore1.png",
      name: "Skyblue Creator",
      price: "0.004 ETH"
  },
  {
      user: "@Alex",
      likes: "152",
      time: "8h : 15m : 25s left",
      stock: "4 in stock",
      text3: "From 1.35 ETH 11/Bid 1.1 w",
      image: "images/Explore/Explore2.png",
      name: "Skyblue Creator",
      price: "0.004 ETH"
  },
  {
      user: "@Alex",
      likes: "152",
      time: "8h : 15m : 25s left",
      stock: "4 in stock",
      text3: "From 1.35 ETH 11/Bid 1.1 w",
      image: "images/Explore/Explore3.png",
      name: "Skyblue Creator",
      price: "0.004 ETH"
  },
  {
      user: "@Alex",
      likes: "152",
      time: "8h : 15m : 25s left",
      stock: "4 in stock",
      text3: "From 1.35 ETH 11/Bid 1.1 w",
      image: "images/Explore/Explore4.png",
      name: "Skyblue Creator",
      price: "0.004 ETH"
  },
]


export const tableData = [
  {
      image:"images/Profile.png",
      name:"Abcd Efght",
      text1:" 133,871.43",
      text2:"-48.31%",
      text3:"+1396.90%",
      text4:"133",
      text6:"3.2 K",
      text5:"10.0K",
    },
  {
    image:"images/Profile.png",
    name:"Abcd Efght",
    text1:" 133,871.43",
    text2:"-48.31%",
    text3:"+1396.90%",
    text4:"133",
    text5:"10.0K",
    text6:"3.2 K"
},
{
    image:"images/Profile.png",
    name:"Abcd Efght",
    text1:" 133,871.43",
    text2:"-48.31%",
    text3:"+1396.90%",
    text4:"133",
    text5:"10.0K",
    text6:"3.2 K"
},
{
    image:"images/Profile.png",
    name:"Abcd Efght",
    text1:" 133,871.43",
    text2:"-48.31%",
    text3:"+1396.90%",
    text4:"133",
    text5:"10.0K",
    text6:"3.2 K"
},
{
    image:"images/Profile.png",
    name:"Abcd Efght",
    text1:" 133,871.43",
    text2:"-48.31%",
    text3:"+1396.90%",
    text4:"133",
    text5:"10.0K",
    text6:"3.2 K"
},
{
    image:"images/Profile.png",
    name:"Abcd Efght",
    text1:" 133,871.43",
    text2:"-48.31%",
    text3:"+1396.90%",
    text4:"133",
    text5:"10.0K",
    text6:"3.2 K"
},
{
    image:"images/Profile.png",
    name:"Abcd Efght",
    text1:" 133,871.43",
    text2:"-48.31%",
    text3:"+1396.90%",
    text4:"133",
    text5:"10.0K",
    text6:"3.2 K"
},
{
    image:"images/Profile.png",
    name:"Abcd Efght",
    text1:" 133,871.43",
    text2:"-48.31%",
    text3:"+1396.90%",
    text4:"133",
    text5:"10.0K",
    text6:"3.2 K"
},
{
    image:"images/Profile.png",
    name:"Abcd Efght",
    text1:" 133,871.43",
    text2:"-48.31%",
    text3:"+1396.90%",
    text4:"133",
    text5:"10.0K",
    text6:"3.2 K"
},



]